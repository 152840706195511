(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/selectors/coupon-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/selectors/coupon-selectors.js');
"use strict";

const {
  createSelector
} = RTK;
const {
  constants
} = svs.components.tipsen.engine;
const {
  getPaymentConfirmMessage
} = svs.components.sport;
const {
  CurrencyAsJupiterString
} = svs.utils.format;
const {
  resolveITSProductId
} = svs.utils.products;
const {
  COUPON_VALID,
  COUPON_INVALID_NO_COMPLETE_BOARD,
  COUPON_INVALID_MAX_SIZE_EXCEEDED,
  COUPON_INVALID_REDUCE_COUNT_TOO_GREAT,
  COUPON_INVALID_REDUCE_COUNT_NOT_IN_RANGE,
  COUPON_INVALID_WRONG_HEDGES,
  COUPON_INVALID_WRONG_USIGNS,
  COUPON_INVALID_BELOW_GROUP_AMOUNT,
  STAKE_OUT_OF_BOUNDS,
  COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE,
  COUPON_INVALID_ZERO_ROWS_REDUCTION,
  COUPON_INVALID_OUTCOME_GROUP_HAS_TO_MANY_SELECTIONS,
  COUPON_INVALID_BOARD_HAS_TO_MANY_SELECTIONS
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  SYSTEM_MSYS,
  SYSTEM_RSYS,
  SYSTEM_USYS,
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  MSYS,
  SROW,
  RSYS,
  USYS,
  UROW
} = svs.components.tipsen.engine.constants.gameBoardSystemType;
const {
  QUICKPICK_WEIGHTED,
  QUICKPICK_PARTIAL,
  QUICKPICK_MODIFIED
} = svs.components.tipsen.engine.constants.quickpickProp;
const {
  BET_PROP_REDUCERA_FRITT,
  BET_PROP_MARKETPLACE,
  BET_PROP_MARKETPLACE_RETAILER
} = svs.components.tipsen.engine.constants.betProp;
const {
  Reducer
} = svs.components.sport.percentageReduce;
const {
  signDistributionConversion
} = svs.components.tipsen.engine.utils;
const {
  convert1X2ToBitmapRows
} = svs.components.sport.expandedSystems.utils;
const {
  selectActiveWallet,
  selectWalletBalance
} = svs.components.tipsen.walletSelectors;
const {
  WALLET_MARKETPLACE_RETAILER,
  WALLET_MARKETPLACE_PLAYER
} = svs.components.tipsen.wallet.walletType;
const {
  isMarketplaceWallet
} = svs.components.tipsen.wallet.walletHelpers;
const {
  BetslipCouponType,
  engineCouponSelectorType,
  ReductionScore,
  ReductionScoreValueToOutcome,
  externalSystemsBoardsType
} = svs.components.tipsen.engine.constants;
const {
  selectCompetitionDetails,
  selectActiveCompetitionId
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  selectEngineDefinition,
  selectEngineDefinitionByProductId,
  engineSelectors
} = svs.components.tipsen.engine.selectors;
const {
  makeSelectRelatedDraw,
  selectNumberOfEvents
} = svs.components.sport.drawsRedux.selectors;
const {
  selectors: {
    selectDraw,
    selectJackpotAmountById,
    selectExtraMoneyById,
    selectRolloverInById
  }
} = svs.components.sport.drawsRedux;
const {
  systems
} = svs.components.sport;
const {
  flatArray
} = svs.components.lbUtils.flatArray;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  bitmapToArray,
  hasBitmapValues,
  getSetBitsInBitmap,
  getBitmapIndices,
  getBinaryNumbers
} = svs.components.sport.tipsenShared.bitmap;
const {
  getCartesianProducts
} = svs.components.tipsen.expandedSystems;
const {
  productIds
} = svs.utils.products;
const selectCouponSelectorType = state => {
  const {
    outcomes
  } = selectEngineDefinition(state);
  const engineType = outcomes.eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
  return engineType;
};
const selectCoupon = (state, couponId) => state.Coupons[couponId];
const selectCouponProductId = (state, couponId) => {
  var _state$Coupons$coupon;
  return (_state$Coupons$coupon = state.Coupons[couponId]) === null || _state$Coupons$coupon === void 0 ? void 0 : _state$Coupons$coupon.productId;
};
const selectIsCouponExternal = (state, couponId) => state.Coupons[couponId].isExternal;
const selectCouponBoard = createSelector([(state, couponId) => selectCoupon(state, couponId), (state, couponId, boardIndex) => boardIndex], (coupon, boardIndex) => coupon === null || coupon === void 0 ? void 0 : coupon.boards[boardIndex]);
const selectIsCouponSingleRows = function (state, couponId) {
  var _selectCouponBoard;
  let boardIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return ((_selectCouponBoard = selectCouponBoard(state, couponId, boardIndex)) === null || _selectCouponBoard === void 0 ? void 0 : _selectCouponBoard.type) === constants.boardType.BOARD_SINGLE;
};
const selectBoardUserSigns = (state, couponId, boardIndex) => {
  var _selectCouponBoard2;
  return ((_selectCouponBoard2 = selectCouponBoard(state, couponId, boardIndex)) === null || _selectCouponBoard2 === void 0 ? void 0 : _selectCouponBoard2.userSigns) || [];
};
const selectCouponEventCount = function (state, couponId) {
  var _selectCouponBoard3;
  let boardIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return (((_selectCouponBoard3 = selectCouponBoard(state, couponId, boardIndex)) === null || _selectCouponBoard3 === void 0 ? void 0 : _selectCouponBoard3.signs) || []).length;
};
const selectUserSigns = (state, couponId) => selectBoardUserSigns(state, couponId, 0);
const defaultSigns = Object.freeze([]);
const selectSigns = createSelector(function (state, couponId) {
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return selectCouponBoard(state, couponId, board);
}, board => {
  var _board$signs;
  return (_board$signs = board === null || board === void 0 ? void 0 : board.signs) !== null && _board$signs !== void 0 ? _board$signs : defaultSigns;
});
const getNumberOfEventsStartedForMatrix = signs => {
  let numberOfEventsStarted = 0;
  if (signs) {
    signs.forEach(event => {
      if (event.includes(true)) {
        numberOfEventsStarted += 1;
      }
    });
  }
  return numberOfEventsStarted;
};
const getNumberOfEventsStartedForBinary = allSignsInCoupon => {
  const numberOfEventsStarted = allSignsInCoupon ? allSignsInCoupon.filter(eventSelections => eventSelections.filter(hasBitmapValues).length === eventSelections.length).length : 0;
  return numberOfEventsStarted;
};
const selectMSigns = function (state, couponId) {
  var _selectCouponBoard4;
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return (_selectCouponBoard4 = selectCouponBoard(state, couponId, board)) === null || _selectCouponBoard4 === void 0 ? void 0 : _selectCouponBoard4.mSigns;
};
const selectUSigns = function (state, couponId) {
  var _selectCouponBoard5;
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return (_selectCouponBoard5 = selectCouponBoard(state, couponId, board)) === null || _selectCouponBoard5 === void 0 ? void 0 : _selectCouponBoard5.uSigns;
};
const makeSelectEventOutcomesFromBitmap = () => createSelector((state, couponId, board) => selectSigns(state, couponId, board), (state, couponId, board, eventNumber) => eventNumber, (state, couponId, board, eventNumber, outcomeGroupIndex) => outcomeGroupIndex, (signs, eventNumber, outcomeGroupIndex) => {
  const bitmap = signs[eventNumber - 1][outcomeGroupIndex];
  const array = bitmapToArray(bitmap);
  return array;
});
const selectEventOutcomesFromBitmap = createSelector((state, couponId, board) => selectSigns(state, couponId, board), (state, couponId, board, event) => event, (state, couponId, board, event, outcomeGroupIndex) => outcomeGroupIndex, (signs, event, outcomeGroupIndex) => {
  const bitmap = signs[event][outcomeGroupIndex];
  const array = bitmapToArray(bitmap);
  return array;
});
const selectEventOutcomesMatrix = (state, couponId, board, event) => {
  const signs = selectSigns(state, couponId, board);
  return signs ? signs[event] : undefined;
};

const selectEventSelections = function (state, couponId, board, event) {
  let outcomeGroupIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  const engineType = selectCouponSelectorType(state);
  let signs;
  if (engineType === engineCouponSelectorType.BINARY) {
    signs = selectEventOutcomesFromBitmap(state, couponId, board, event, outcomeGroupIndex);
  } else {
    signs = selectEventOutcomesMatrix(state, couponId, board, event);
  }
  return signs;
};
const selectEventUserSelections = (state, couponId, board, event) => {
  const signs = selectBoardUserSigns(state, couponId, board);
  return signs[event];
};
const selectEventMSign = (state, couponId, board, event) => {
  const mSigns = selectMSigns(state, couponId, board);
  return mSigns ? mSigns[event] : undefined;
};
const selectEventUSign = (state, couponId, board, event) => {
  const uSigns = selectUSigns(state, couponId, board);
  return uSigns ? uSigns[event] : undefined;
};

const selectSelectedSystemType = (state, couponId) => {
  let system;
  const engineConfig = selectEngineDefinition(state);
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails) {
    system = competitionDetails.systemType;
  } else {
    var _selectCoupon;
    system = engineConfig.systems[((_selectCoupon = selectCoupon(state, couponId)) === null || _selectCoupon === void 0 ? void 0 : _selectCoupon.selectedSystem) || 0];
  }
  return system;
};
const selectRSystem = (state, couponId) => {
  let rSys = null;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails && competitionDetails.systemType === SYSTEM_RSYS) {
    rSys = competitionDetails.systemNumber;
  } else {
    var _selectCoupon2;
    rSys = (_selectCoupon2 = selectCoupon(state, couponId)) === null || _selectCoupon2 === void 0 ? void 0 : _selectCoupon2.rSystem;
  }
  return rSys;
};
const selectUSystem = (state, couponId) => {
  let uSys = null;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails && competitionDetails.systemType === SYSTEM_USYS) {
    uSys = competitionDetails.systemNumber;
  } else {
    var _selectCoupon3;
    uSys = (_selectCoupon3 = selectCoupon(state, couponId)) === null || _selectCoupon3 === void 0 ? void 0 : _selectCoupon3.uSystem;
  }
  return uSys;
};

const selectSystemType = (state, couponId) => {
  const system = selectSelectedSystemType(state, couponId);
  const rSys = selectRSystem(state, couponId);
  const uSys = selectUSystem(state, couponId);
  if (system === SYSTEM_RSYS && !rSys) {
    return SYSTEM_MSYS;
  }
  if (system === SYSTEM_USYS && !uSys) {
    return SYSTEM_MSYS;
  }
  return system;
};
const selectIsTransferable = (state, couponId) => {
  var _coupon$isExternal;
  const systemType = selectSystemType(state, couponId);
  const coupon = selectCoupon(state, couponId);
  const transferable = (_coupon$isExternal = !coupon.isExternal) !== null && _coupon$isExternal !== void 0 ? _coupon$isExternal : true;
  return transferable && systemType !== SYSTEM_REDUCERA_FRITT;
};
const selectHalfHedges = (state, couponId, boardIndex) => {
  const systemType = selectSystemType(state, couponId);
  const signs = selectSigns(state, couponId, boardIndex) || [];
  return signs.filter((row, index) => {
    const selections = row.filter(Boolean).length;
    const moreThanOneSign = selections > 1;
    const notAllSigns = row.length !== selections;
    const noMSign = !((systemType === SYSTEM_RSYS || systemType === SYSTEM_USYS) && selectEventMSign(state, couponId, boardIndex, index));
    return moreThanOneSign && notAllSigns && noMSign;
  }).length;
};
const selectFullHedges = (state, couponId, boardIndex) => {
  const systemType = selectSystemType(state, couponId);
  const signs = selectSigns(state, couponId, boardIndex) || [];
  return signs.filter((row, index) => {
    const allSigns = row.length === row.filter(Boolean).length;
    const noMSign = !((systemType === SYSTEM_RSYS || systemType === SYSTEM_USYS) && selectEventMSign(state, couponId, boardIndex, index));
    return allSigns && noMSign;
  }).length;
};
const selectCouponSet = (state, couponId, boardIndex) => {
  const signs = selectSigns(state, couponId, boardIndex) || [];
  const set = {};
  signs.forEach(row => {
    const selectedCount = row.filter(sign => sign).length;
    if (!set[selectedCount]) {
      set[selectedCount] = 1;
    } else {
      set[selectedCount] += 1;
    }
  });
  return set;
};
const selectCurrentBetAmount = (state, couponId) => {
  var _selectCoupon4;
  return ((_selectCoupon4 = selectCoupon(state, couponId)) === null || _selectCoupon4 === void 0 ? void 0 : _selectCoupon4.betAmount) || 1;
};
const getEventSelectionCount = eventSigns => eventSigns.reduce((acc, outcomes) => {
  const selectedOutcomes = getSetBitsInBitmap(outcomes);
  if (!acc && selectedOutcomes) {
    acc = 1;
  }
  return selectedOutcomes ? acc * selectedOutcomes : acc;
}, 0);
const selectReduceCount = (state, couponId) => {
  var _selectCoupon5;
  return ((_selectCoupon5 = selectCoupon(state, couponId)) === null || _selectCoupon5 === void 0 ? void 0 : _selectCoupon5.reduceModeCount) || 0;
};
const selectReductions = function (state, couponId) {
  var _board$reduction;
  let boardIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const board = selectCouponBoard(state, couponId, boardIndex);
  return (_board$reduction = board === null || board === void 0 ? void 0 : board.reduction) !== null && _board$reduction !== void 0 ? _board$reduction : [];
};
const selectReductionForEvent = function (state, couponId, eventNumber) {
  let boardIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  const reductions = selectReductions(state, couponId, boardIndex);
  const reduction = reductions ? reductions[eventNumber - 1] : undefined;
  return reduction !== null && reduction !== void 0 ? reduction : 0;
};
const selectReductionSelectionsForEvent = function (state, couponId, eventNumber) {
  let boardIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  const reduction = selectReductionForEvent(state, couponId, eventNumber, boardIndex);
  const engineReduction = engineSelectors.selectReduction(state);
  const reductionSelections = getBinaryNumbers(reduction);
  const selectedReductionsForEvent = engineReduction.filter(reduction => !reductionSelections.includes(reduction));
  return selectedReductionsForEvent;
};
const selectReductionSelections = function (state, couponId) {
  let boardIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const signs = selectSigns(state, couponId, boardIndex) || [];
  const reductionSelections = signs.map((_, index) => selectReductionSelectionsForEvent(state, couponId, index + 1, boardIndex));
  return reductionSelections;
};
const filterFn = (h, a) => h - a;
const homeFilter = (h, a) => filterFn(h, a) > 0;
const drawFilter = (h, a) => filterFn(h, a) === 0;
const awayFilter = (h, a) => filterFn(h, a) < 0;
const scoreFilterFnMap = {
  [ReductionScore.HOME]: homeFilter,
  [ReductionScore.DRAW]: drawFilter,
  [ReductionScore.AWAY]: awayFilter
};
const getSROWFromSigns = (eventSigns, reduction) => {
  const reductionArray = getBinaryNumbers(reduction); 
  const filterFns = reductionArray.map(reduction => scoreFilterFnMap[reduction]);
  const bitmapIndexContainers = eventSigns.map(signs => getBitmapIndices(signs).flat()); 
  const outcomeCombinations = getCartesianProducts(...bitmapIndexContainers); 
  const validSrows = outcomeCombinations.filter(result => filterFns.some(filter => filter(...result))); 

  return validSrows;
};

const selectSROWForEvent = function (state, couponId, eventNumber) {
  let boardIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  const signs = selectSigns(state, couponId, boardIndex);
  const eventSigns = signs[eventNumber - 1];
  const reduction = selectReductionForEvent(state, couponId, eventNumber, boardIndex);
  return getSROWFromSigns(eventSigns, reduction);
};

const composeAllSROWs = function () {
  let outcomesArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (index === outcomesArray.length) {
    return [current];
  }
  let sROWs = [];
  for (let i = 0; i < outcomesArray[index].length; i++) {
    const next = current.concat([outcomesArray[index][i]]);
    sROWs = sROWs.concat(composeAllSROWs(outcomesArray, next, index + 1));
  }
  return sROWs;
};

const makeSelectAllSROWCombinations = () => createSelector(selectSigns, selectReductions, (selectedSigns, reductions) => {
  const totalSROWs = selectedSigns.map((signsByEvent, index) => getSROWFromSigns(signsByEvent, reductions[index]));
  return composeAllSROWs(totalSROWs);
});
const makeSelectSROWCombinationCount = () => createSelector(selectSigns, selectReductions, (selectedSigns, reductions) => {
  const totalSROWs = selectedSigns.map((signsByEvent, index) => getSROWFromSigns(signsByEvent, reductions[index]).length);
  const totalCombinations = totalSROWs.reduce((acc, count) => acc * (count || 1), 1);
  return totalCombinations;
});
const selectIsEventReduced = function (state, couponId, eventNumber) {
  let boardIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  const reduction = selectReductionForEvent(state, couponId, eventNumber, boardIndex);
  const engineReduction = engineSelectors.selectReductionSum(state);
  return reduction !== engineReduction;
};
const selectSystemTypeForEvent = function (state, couponId, eventNumber) {
  let boardIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  const isEventReduced = selectIsEventReduced(state, couponId, eventNumber, boardIndex);
  const coupon = selectCoupon(state, couponId);
  const productId = coupon.productId;
  const engineConfig = selectEngineDefinitionByProductId(productId);
  const isEventTypeResult = engineConfig.outcomes.eventTypeId === EventTypeId.RESULT;
  const label = isEventReduced ? 'R' : '';
  let rowCount;
  if (isEventReduced || isEventTypeResult) {
    rowCount = selectSROWForEvent(state, couponId, eventNumber, boardIndex).length;
  } else {
    const signs = selectSigns(state, couponId, boardIndex);
    const eventSigns = signs[eventNumber - 1];
    rowCount = getEventSelectionCount(eventSigns);
  }
  return "".concat(label).concat(rowCount);
};
const _selectSystemSize = signs => {
  const systemSize = signs.reduce((map, row) => {
    const selections = row.filter(Boolean).length;
    return selections ? map * selections : map;
  }, 1);
  return systemSize;
};
const selectUserSystemSize = (state, couponId, boardIndex) => {
  const signs = selectBoardUserSigns(state, couponId, boardIndex);
  return _selectSystemSize(signs);
};
const selectAllSigns = createSelector((state, couponId) => {
  var _state$Coupons$coupon2;
  return (_state$Coupons$coupon2 = state.Coupons[couponId]) === null || _state$Coupons$coupon2 === void 0 ? void 0 : _state$Coupons$coupon2.boards;
}, boards => {
  const allSigns = [];
  boards.forEach(board => {
    allSigns.push(board.signs);
  });
  return allSigns;
});
const selectSystemSizeMatrix = createSelector((state, couponId, boardIndex) => selectSigns(state, couponId, boardIndex), signs => _selectSystemSize(signs));
const selectSystemSizeBinary = createSelector((state, couponId, boardIndex) => selectSigns(state, couponId, boardIndex), (state, couponId, boardIndex) => selectReductions(state, couponId, boardIndex), engineSelectors.selectReductionSum, (signs, eventReductions, engineReductionSum) => {
  const systemSize = signs.reduce((map, eventSigns, eventIndex) => {
    var _eventReductions$even;
    const eventReduction = (_eventReductions$even = eventReductions[eventIndex]) !== null && _eventReductions$even !== void 0 ? _eventReductions$even : 0;
    const isEventReduced = eventReduction !== engineReductionSum;
    const selections = isEventReduced ? getSROWFromSigns(eventSigns, eventReduction).length : getEventSelectionCount(eventSigns);
    return map * (selections || 1);
  }, 1);
  return systemSize;
});
const selectSystemSize = (state, couponId, boardIndex) => {
  const couponSelectorType = selectCouponSelectorType(state);
  if (couponSelectorType === engineCouponSelectorType.MATRIX) {
    return selectSystemSizeMatrix(state, couponId, boardIndex);
  }
  return selectSystemSizeBinary(state, couponId, boardIndex);
};
const selectMSign = (state, couponId, board, event) => ({
  disabled: (selectEventSelections(state, couponId, board, event) || []).filter(sign => sign).length < 2,
  value: selectEventMSign(state, couponId, board, event)
});
const selectSignDistribution = (state, couponId, board, event) => {
  var _selectCouponBoard6;
  const signDistribution = ((_selectCouponBoard6 = selectCouponBoard(state, couponId, board)) === null || _selectCouponBoard6 === void 0 ? void 0 : _selectCouponBoard6.signDistribution) || [];
  if (typeof event !== 'undefined') {
    return signDistribution[event];
  }
  return signDistribution;
};
const selectRequiredHedges = (state, couponId) => {
  const selectedSystemType = selectSystemType(state, couponId);
  let requiredHalfHedges = null;
  let requiredFullHedges = null;
  if (selectedSystemType === SYSTEM_RSYS || selectedSystemType === SYSTEM_USYS) {
    const systemNum = {
      [SYSTEM_RSYS]: selectRSystem,
      [SYSTEM_USYS]: selectUSystem
    }[selectedSystemType](state, couponId);
    const system = systems.getSystemFromNumber(systemNum);
    ({
      numHalfHedges: requiredHalfHedges,
      numFullHedges: requiredFullHedges
    } = system);
  }
  return {
    requiredHalfHedges,
    requiredFullHedges
  };
};
const selectRequiredUSigns = (state, couponId) => {
  const {
    requiredHalfHedges,
    requiredFullHedges
  } = selectRequiredHedges(state, couponId);
  return !requiredHalfHedges && !requiredFullHedges ? undefined : requiredFullHedges + requiredHalfHedges;
};
const selectCurrentWeek = (state, couponId) => {
  var _selectCoupon6;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const wallet = selectActiveWallet(state);
  const isActiveMarketplace = isMarketplaceWallet(wallet);
  if (activeCompetitionId || isActiveMarketplace && !svs.core.detect.feature('ft-marketplace-multiple-weeks')) {
    return 1;
  }
  return selectEngineDefinition(state).weeks[((_selectCoupon6 = selectCoupon(state, couponId)) === null || _selectCoupon6 === void 0 ? void 0 : _selectCoupon6.week) || 0];
};
const selectCurrentAddonPixSizeIndex = (state, couponId) => {
  var _selectCoupon7;
  const pixSizes = selectEngineDefinition(state).addonPixSize;
  const pixSizeIndex = pixSizes.indexOf(selectEngineDefinition(state).addonPixSize[((_selectCoupon7 = selectCoupon(state, couponId)) === null || _selectCoupon7 === void 0 ? void 0 : _selectCoupon7.selectedAddonPixIndex) || 0]);
  return pixSizeIndex;
};
const selectRelatedDrawCoupon = (state, couponId) => {
  const coupon = selectCoupon(state, couponId);
  let relatedCoupon;
  if (coupon) {
    const {
      productId,
      drawNumber
    } = coupon;
    const relatedDraw = makeSelectRelatedDraw(productId, drawNumber, constants.RelatedProductType.PIX.toUpperCase())(state) || {};
    const {
      productId: relatedProductId,
      drawNumber: relatedDrawNumber
    } = relatedDraw;
    const relatedCouponId = "".concat(relatedProductId, "_").concat(relatedDrawNumber, "_pix");
    relatedCoupon = selectCoupon(state, relatedCouponId);
  }
  return relatedCoupon;
};
const selectIsSportkryssSupported = (state, couponId) => {
  const wallet = selectActiveWallet(state);
  const marketplaceWallet = isMarketplaceWallet(wallet);
  const isReduceFreely = selectSystemType(state, couponId) === SYSTEM_REDUCERA_FRITT;
  const isSportkryssSupported = !marketplaceWallet || marketplaceWallet && !isReduceFreely;
  return isSportkryssSupported;
};
const selectCurrentSportkryssFactor = (state, couponId) => {
  if (selectIsSportkryssSupported(state, couponId)) {
    var _selectCoupon8;
    return selectEngineDefinition(state).sportkryssFactors[((_selectCoupon8 = selectCoupon(state, couponId)) === null || _selectCoupon8 === void 0 ? void 0 : _selectCoupon8.sportkryss) || 0];
  }
  return 0;
};
const selectCurrentSportkryssAmount = (state, couponId) => selectCurrentSportkryssFactor(state, couponId) * selectEngineDefinition(state).sportkryssBase;
const selectCouponBoardMSigns = createSelector((state, couponId, boardIndex) => selectSigns(state, couponId, boardIndex), (state, couponId, boardIndex) => selectMSigns(state, couponId, boardIndex), function () {
  let signs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let mSigns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return signs.map(signs => signs.filter(Boolean).length).filter((dummy, i) => mSigns[i]);
});
const selectBoardPrice = (state, couponId, boardIndex, preCalculatedSystemSize) => {
  const betAmount = selectCurrentBetAmount(state, couponId);
  const systemSize = preCalculatedSystemSize !== null && preCalculatedSystemSize !== void 0 ? preCalculatedSystemSize : selectSystemSize(state, couponId, boardIndex);
  const reduceCount = selectReduceCount(state, couponId);
  const selectedSystemType = selectSystemType(state, couponId);
  const rSystem = selectRSystem(state, couponId);
  const uSystem = selectUSystem(state, couponId);
  const week = selectCurrentWeek(state, couponId);
  let basePrice;
  if (selectedSystemType === SYSTEM_REDUCERA_FRITT) {
    basePrice = reduceCount !== null && reduceCount !== void 0 ? reduceCount : 0;
  } else {
    const selectedSystem = {
      [SYSTEM_RSYS]: systems.getSystemFromNumber(rSystem),
      [SYSTEM_USYS]: systems.getSystemFromNumber(uSystem)
    }[selectedSystemType];
    if (selectedSystem) {
      const couponMSigns = selectCouponBoardMSigns(state, couponId, boardIndex);
      basePrice = couponMSigns.reduce((amount, sum) => amount * sum, selectedSystem.rows.length);
    } else {
      basePrice = betAmount * systemSize;
    }
  }
  return basePrice * week;
};
const selectIsEveryMatchSelected = (state, couponId, boardIndex) => {
  const signs = selectSigns(state, couponId, boardIndex) || [];
  const engineType = selectCouponSelectorType(state);
  if (engineType === engineCouponSelectorType.BINARY) {
    const isEveryMatchSelected = signs.filter(eventSelections => eventSelections.filter(hasBitmapValues).length === eventSelections.length).length === signs.length;
    return isEveryMatchSelected;
  }
  const isEveryMatchSelected = signs.filter(selections => selections.filter(Boolean).length > 0).length === signs.length;
  return isEveryMatchSelected;
};
const selectIsEveryMatchSelectedWithUserSigns = (state, couponId, boardIndex) => {
  const signs = selectBoardUserSigns(state, couponId, boardIndex) || [];
  const engineType = selectCouponSelectorType(state);
  if (engineType === engineCouponSelectorType.BINARY) {
    const isEveryMatchSelected = signs.filter(eventSelections => eventSelections.filter(hasBitmapValues).length === eventSelections.length).length === signs.length;
    return isEveryMatchSelected;
  }
  const isEveryMatchSelected = signs.filter(selections => selections.filter(Boolean).length > 0).length === signs.length;
  return isEveryMatchSelected;
};
const selectPriceOfBoards = (state, couponId, couponBoards) => {
  let price = 0;
  const hasMultipleBoards = couponBoards.length > 1;
  for (let boardIndex = 0; boardIndex < couponBoards.length; ++boardIndex) {
    var _selectCoupon9;
    if (!((_selectCoupon9 = selectCoupon(state, couponId)) !== null && _selectCoupon9 !== void 0 && _selectCoupon9.isExternal)) {
      const includeBoard = !hasMultipleBoards || selectIsEveryMatchSelected(state, couponId, boardIndex);
      if (includeBoard) {
        price += selectBoardPrice(state, couponId, boardIndex);
      }
    } else {
      price += selectBoardPrice(state, couponId, boardIndex, couponBoards[boardIndex].systemSize);
    }
  }
  return price;
};
const selectPriceOfAllBoards = (state, couponId) => {
  var _selectCoupon10;
  const couponBoards = ((_selectCoupon10 = selectCoupon(state, couponId)) === null || _selectCoupon10 === void 0 ? void 0 : _selectCoupon10.boards) || [];
  const price = selectPriceOfBoards(state, couponId, couponBoards);
  return price;
};
const selectSportkryssPrice = (state, couponId) => {
  const week = selectCurrentWeek(state, couponId);
  const sportkryssFactor = selectCurrentSportkryssFactor(state, couponId);
  const sportkryssBase = selectEngineDefinition(state).sportkryssBase;
  const sportkryssPrice = sportkryssFactor * sportkryssBase * week;
  return sportkryssPrice;
};
const selectSportkryssNumber = (state, couponId) => {
  var _selectCoupon11;
  return ((_selectCoupon11 = selectCoupon(state, couponId)) === null || _selectCoupon11 === void 0 ? void 0 : _selectCoupon11.sportkryssNumber) || '';
};
const selectIsRelatedCouponValid = (state, couponId) => {
  const couponStatus = selectCouponStatus(state, couponId);
  const pixSize = selectCurrentAddonPixSizeIndex(state, couponId);
  return Boolean(pixSize && couponStatus === COUPON_VALID);
};
const selectRelatedCouponPrice = (state, couponId) => {
  const relatedCoupon = selectRelatedDrawCoupon(state, couponId);
  if (!relatedCoupon) {
    return 0;
  }
  const {
    productId: relatedProductId,
    drawNumber: relatedDrawNumber
  } = relatedCoupon;
  const relatedCouponId = "".concat(relatedProductId, "_").concat(relatedDrawNumber, "_pix");
  const pixSizes = engineSelectors.selectAddonPixSizes(state);
  const selectedPixIndex = selectCurrentAddonPixSizeIndex(state, relatedCouponId);
  return selectedPixIndex ? pixSizes[selectedPixIndex] : 0;
};
const selectPrice = createSelector((state, couponId) => selectPriceOfAllBoards(state, couponId), (state, couponId) => selectSportkryssPrice(state, couponId), (state, couponId) => selectRelatedCouponPrice(state, couponId), state => selectActiveWallet(state), function (state, couponId) {
  let shouldSelectRelated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return shouldSelectRelated;
}, (priceOfAllBoards, sportkryssPrice, relatedPrice, wallet, shouldSelectRelated) => {
  let totalPrice = priceOfAllBoards + sportkryssPrice;
  if (shouldSelectRelated && !isMarketplaceWallet(wallet)) {
    totalPrice += relatedPrice;
  }
  return totalPrice;
});
const getReduceModeStatus = (state, couponId, boardIndex) => {
  const reduceModeCount = selectReduceCount(state, couponId);
  const isReduceModeCountWithinRange = reduceModeCount >= constants.reduceModeRange.MIN && reduceModeCount <= constants.reduceModeRange.MAX;
  if (!isReduceModeCountWithinRange) {
    return COUPON_INVALID_REDUCE_COUNT_NOT_IN_RANGE;
  }
  const possibleRows = selectSystemSize(state, couponId, boardIndex);
  const isReduceModeCountValid = reduceModeCount < possibleRows;
  if (!isReduceModeCountValid) {
    return COUPON_INVALID_REDUCE_COUNT_TOO_GREAT;
  }
};
const getSystemStatus = (state, couponId, boardIndex) => {
  const {
    requiredHalfHedges,
    requiredFullHedges
  } = selectRequiredHedges(state, couponId);
  const selectedFullHedges = selectFullHedges(state, couponId, boardIndex);
  const selectedHalfHedges = selectHalfHedges(state, couponId, boardIndex);
  if (selectedFullHedges !== requiredFullHedges || selectedHalfHedges !== requiredHalfHedges) {
    return COUPON_INVALID_WRONG_HEDGES;
  }
  const selectedSystemType = selectSystemType(state, couponId);
  if (selectedSystemType === SYSTEM_USYS) {
    const numHedges = selectedFullHedges + selectedHalfHedges;
    const selectedUSigns = (selectUSigns(state, couponId, boardIndex) || []).filter(uSign => uSign !== null);
    const isUSignsOK = numHedges === selectedUSigns.length;
    if (!isUSignsOK) {
      return COUPON_INVALID_WRONG_USIGNS;
    }
  }
};
const selectCouponSignCountMatrix = RTK.createSelector(selectCoupon, coupon => {
  var _coupon$boards;
  const boards = (_coupon$boards = coupon === null || coupon === void 0 ? void 0 : coupon.boards) !== null && _coupon$boards !== void 0 ? _coupon$boards : [];
  const init = 0;
  const signsCount = flatArray(boards.map(b => b.signs.map(r => r.filter(s => s).length))).reduce((acc, curr) => acc + curr, init);
  return signsCount;
});
const selectCouponSignCountBinary = RTK.createSelector(selectCoupon, coupon => {
  var _coupon$boards2;
  const boards = (_coupon$boards2 = coupon === null || coupon === void 0 ? void 0 : coupon.boards) !== null && _coupon$boards2 !== void 0 ? _coupon$boards2 : [];
  const init = 0;
  const signsCount = flatArray(boards.map(b => b.signs.map(r => {
    const arrayFromBitmap = flatArray(r.map(signsBitmap => bitmapToArray(signsBitmap)));
    return arrayFromBitmap.filter(s => s).length;
  }))).reduce((acc, curr) => acc + curr, init);
  return signsCount;
});
const selectCouponSignCount = (state, couponId) => {
  const engineType = selectCouponSelectorType(state);
  if (engineType === engineCouponSelectorType.BINARY) {
    return selectCouponSignCountBinary(state, couponId);
  }
  return selectCouponSignCountMatrix(state, couponId);
};
const selectAnyOutcomeGroupHasToManySelections = (state, couponId, boardIndex, maxSelectionOutcomeGroups) => {
  const signs = selectSigns(state, couponId, boardIndex) || [];
  for (const eventSelections of signs) {
    for (const outcomeGroup of eventSelections) {
      if (getBitmapIndices(outcomeGroup).flat().length > maxSelectionOutcomeGroups) {
        return true;
      }
    }
  }
  return false;
};
const selectCouponStatus = (state, couponId) => {
  var _selectCoupon12;
  const coupon = selectCoupon(state, couponId);
  if (coupon !== null && coupon !== void 0 && coupon.isExternal) {
    return COUPON_VALID;
  }
  let numCompleteRows = 0;
  let hasIncompleteRow = false;
  const productId = coupon === null || coupon === void 0 ? void 0 : coupon.productId;
  const drawNumber = coupon === null || coupon === void 0 ? void 0 : coupon.drawNumber;
  const draw = selectDraw(state, productId, drawNumber);
  const price = selectPrice(state, couponId);
  const minStake = selectEngineDefinition(state).minStake;
  const maxStake = selectEngineDefinition(state).maxBetAmount;
  const couponBoards = ((_selectCoupon12 = selectCoupon(state, couponId)) === null || _selectCoupon12 === void 0 ? void 0 : _selectCoupon12.boards) || [];
  const hasMultipleBoards = couponBoards.length > 1;
  const maxSelectionOutcomeGroups = engineSelectors.selectMaxSelectionOutcomeGroup(state);
  const shouldValiateSelections = maxSelectionOutcomeGroups > 0;
  const betAmount = selectCurrentBetAmount(state, couponId);
  for (let boardIndex = 0; boardIndex < couponBoards.length; ++boardIndex) {
    const systemSize = selectSystemSize(state, couponId, boardIndex);
    const maxSystemSize = selectEngineDefinition(state).maxSystemSize;
    const hasMaxSystemSize = maxSystemSize > 0;
    const selectedSystemType = selectSystemType(state, couponId);
    const maxWagerAmount = selectEngineDefinition(state).maxWagerAmount;
    const hasMaxWagerAmount = maxWagerAmount > 0;
    if (selectedSystemType === SYSTEM_MSYS && hasMaxSystemSize && systemSize > maxSystemSize) {
      return COUPON_INVALID_MAX_SIZE_EXCEEDED;
    }
    if (selectedSystemType === SYSTEM_MSYS && hasMaxWagerAmount && price > maxWagerAmount) {
      return COUPON_INVALID_MAX_SIZE_EXCEEDED;
    }
    if (shouldValiateSelections) {
      const signCount = selectCouponSignCount(state, couponId);
      const eventCount = selectCouponEventCount(state, couponId);
      const signs = selectSigns(state, couponId, boardIndex);
      const numberOfOutcomeGroups = signs[0].length;
      const totalMaxSelections = maxSelectionOutcomeGroups * eventCount * numberOfOutcomeGroups;
      if (signCount > totalMaxSelections) {
        return COUPON_INVALID_BOARD_HAS_TO_MANY_SELECTIONS;
      }
      if (selectAnyOutcomeGroupHasToManySelections(state, couponId, boardIndex, maxSelectionOutcomeGroups)) {
        return COUPON_INVALID_OUTCOME_GROUP_HAS_TO_MANY_SELECTIONS;
      }
    }
    const isBoardComplete = selectIsEveryMatchSelected(state, couponId, boardIndex);
    if (hasMultipleBoards) {
      if (isBoardComplete) {
        numCompleteRows += 1;
        continue;
      } else {
        hasIncompleteRow = true;
      }
    } else if (!isBoardComplete) {
      return COUPON_INVALID_NO_COMPLETE_BOARD;
    }
    let systemStatus;
    if (selectedSystemType === SYSTEM_REDUCERA_FRITT) {
      systemStatus = getReduceModeStatus(state, couponId, boardIndex);
    } else if (selectedSystemType === SYSTEM_RSYS || selectedSystemType === SYSTEM_USYS) {
      systemStatus = getSystemStatus(state, couponId, boardIndex);
    }
    if (systemStatus) {
      return systemStatus;
    }
    const numberOfEvents = selectSigns(state, couponId, boardIndex).length;

    for (let eventNumber = 1; eventNumber <= numberOfEvents; eventNumber++) {
      if (selectIsEventReduced(state, couponId, eventNumber, boardIndex) && !selectSROWForEvent(state, couponId, eventNumber, boardIndex).length) {
        return COUPON_INVALID_ZERO_ROWS_REDUCTION;
      }
    }
  }

  if (hasIncompleteRow && numCompleteRows === 0) {
    return COUPON_INVALID_NO_COMPLETE_BOARD;
  }
  const wallet = selectActiveWallet(state);
  const {
    minGroupAmount
  } = selectEngineDefinition(state);
  if ((wallet === null || wallet === void 0 ? void 0 : wallet.type) === WALLET_MARKETPLACE_RETAILER && price < minGroupAmount) {
    return COUPON_INVALID_BELOW_GROUP_AMOUNT;
  }
  if (betAmount < minStake || maxStake > 0 && betAmount > maxStake) {
    return STAKE_OUT_OF_BOUNDS;
  }
  const shouldIgnoreCompetition = draw && draw.isLite;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails && !shouldIgnoreCompetition) {
    const sysType = competitionDetails.systemType;
    if (sysType === SYSTEM_MSYS) {
      const systemSize = selectSystemSize(state, couponId, 0);
      if (systemSize !== competitionDetails.systemNumber) {
        return COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE;
      }
    } else if (sysType === SYSTEM_RSYS) {
      return COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE;
    } else if (sysType === SYSTEM_USYS) {
      return COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE;
    }
  }
  return COUPON_VALID;
};
const selectHasBoardUserSigns = (state, couponId, boardIndex) => {
  const userSigns = selectBoardUserSigns(state, couponId, boardIndex);
  for (const row of userSigns) {
    if (row.includes(true)) {
      return true;
    }
  }
  return false;
};
const selectHasBoardSignsNotByUser = (state, couponId, boardIndex) => {
  const {
    signs,
    userSigns
  } = selectCouponBoard(state, couponId, boardIndex) || {
    signs: [],
    userSigns: []
  };
  for (const [rowIndex, rowValues] of Object.entries(signs)) {
    if (rowValues.includes(true) && rowValues.toString() !== userSigns[rowIndex].toString()) {
      return true;
    }
  }
  return false;
};
const selectBoardQuickpickVariant = (state, couponId, boardIndex) => {
  var _board$pixVariant;
  const board = selectCouponBoard(state, couponId, boardIndex);
  return (_board$pixVariant = board === null || board === void 0 ? void 0 : board.pixVariant) !== null && _board$pixVariant !== void 0 ? _board$pixVariant : null;
};
const selectIsCouponModified = (state, couponId) => {
  var _coupon$isModified;
  const coupon = selectCoupon(state, couponId);
  return (_coupon$isModified = coupon === null || coupon === void 0 ? void 0 : coupon.isModified) !== null && _coupon$isModified !== void 0 ? _coupon$isModified : false;
};
const selectCouponQuickpickVariant = (state, couponId) => {
  var _selectCoupon13;
  const couponBoards = ((_selectCoupon13 = selectCoupon(state, couponId)) === null || _selectCoupon13 === void 0 ? void 0 : _selectCoupon13.boards) || [];
  const foundPixVariants = {};
  for (const board of couponBoards) {
    if (board.pixVariant && !foundPixVariants[board.pixVariant]) {
      foundPixVariants[board.pixVariant] = board.pixVariant;
    }
  }
  return foundPixVariants[QUICKPICK_MODIFIED] || foundPixVariants[QUICKPICK_PARTIAL] || foundPixVariants[QUICKPICK_WEIGHTED] || null;
};
const selectHasUserSigns = (state, couponId) => {
  var _selectCoupon14;
  const couponBoards = ((_selectCoupon14 = selectCoupon(state, couponId)) === null || _selectCoupon14 === void 0 ? void 0 : _selectCoupon14.boards) || [];
  for (const board of couponBoards) {
    const hasUserSigns = Array.isArray(board.userSigns) && board.userSigns.some(userSignRow => Array.isArray ? userSignRow.includes(true) : userSignRow);
    if (hasUserSigns) {
      return true;
    }
  }
  return false;
};
const selectExternalSystemChunkSize = (state, couponId) => {
  var _coupon$boards$;
  const {
    externalChunkSize: {
      SROW,
      MSYS,
      SROW_MARKETPLACE_GROUP,
      MSYS_MARKETPLACE_GROUP
    },
    drawProductIds
  } = selectEngineDefinition(state);
  const coupon = selectCoupon(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon;
  const boardType = coupon === null || coupon === void 0 || (_coupon$boards$ = coupon.boards[0]) === null || _coupon$boards$ === void 0 ? void 0 : _coupon$boards$.type;
  const wallet = selectActiveWallet(state);
  const isMarketPlace = isMarketplaceWallet(wallet) ? wallet.label : null;
  const numberOfEventsFromDraw = selectNumberOfEvents(state, productId, drawNumber);
  const numberOfEvents = drawProductIds[0] === productIds.BOMBEN ? numberOfEventsFromDraw : 1;
  let maxChunkSize = 0;
  if (boardType === externalSystemsBoardsType.single) {
    maxChunkSize = isMarketPlace ? SROW_MARKETPLACE_GROUP : SROW;
  } else {
    maxChunkSize = isMarketPlace ? MSYS_MARKETPLACE_GROUP : MSYS;
  }
  if (!maxChunkSize) {
    return 0;
  }
  const chunkSize = maxChunkSize / numberOfEvents;
  return chunkSize;
};
const selectMSignsCount = (state, couponId) => {
  var _coupon$boards3;
  const coupon = selectCoupon(state, couponId);
  const boards = (_coupon$boards3 = coupon === null || coupon === void 0 ? void 0 : coupon.boards) !== null && _coupon$boards3 !== void 0 ? _coupon$boards3 : [{
    mSigns: []
  }];
  const init = 0;
  const signsCount = boards.map(b => b.mSigns.filter(r => r).length).reduce((acc, curr) => acc + curr, init);
  return signsCount;
};
const selectCouponEmpty = (state, couponId) => {
  const engineType = selectCouponSelectorType(state);
  if (engineType === engineCouponSelectorType.BINARY) {
    return Boolean(selectCouponSignCount(state, couponId) === 0);
  }
  return Boolean(selectCouponSignCount(state, couponId) === 0);
};
const selectCouponBoardEmpty = (state, couponId, boardIndex) => {
  const signs = selectSigns(state, couponId, boardIndex) || [];
  for (const row of signs) {
    if (row.includes(true)) {
      return false;
    }
  }
  return true;
};
const selectBetslipCouponType = (state, couponId) => {
  var _selectCoupon15;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails) {
    return BetslipCouponType.Competition;
  }
  const isExternal = selectIsCouponExternal(state, couponId);
  if (isExternal) {
    return BetslipCouponType.ExternaSystemspel;
  }
  if ((((_selectCoupon15 = selectCoupon(state, couponId)) === null || _selectCoupon15 === void 0 ? void 0 : _selectCoupon15.boards) || []).length > 1) {
    return BetslipCouponType.Enkelrader;
  }
  const systemType = selectSystemType(state, couponId);
  switch (systemType) {
    case SYSTEM_MSYS:
      return BetslipCouponType.Standard;
    case SYSTEM_RSYS:
      return BetslipCouponType.RSystem;
    case SYSTEM_USYS:
      return BetslipCouponType.USystem;
    case SYSTEM_REDUCERA_FRITT:
      return BetslipCouponType.ReduceraFritt;
    default:
      throw new Error("Unknown Betslip Coupon Type: ".concat(systemType));
  }
};
const selectNumberOfBoards = (state, couponId) => {
  var _selectCoupon16;
  return (((_selectCoupon16 = selectCoupon(state, couponId)) === null || _selectCoupon16 === void 0 ? void 0 : _selectCoupon16.boards) || []).length;
};
const selectNumberOfCompletedBoards = (state, couponId) => {
  var _selectCoupon17;
  let c = 0;
  const couponBoards = ((_selectCoupon17 = selectCoupon(state, couponId)) === null || _selectCoupon17 === void 0 ? void 0 : _selectCoupon17.boards) || [];
  for (const board of couponBoards) {
    let boardIsEmpty = false;
    for (const row of board.signs) {
      if (!row.includes(true)) {
        boardIsEmpty = true;
        break;
      }
    }
    if (!boardIsEmpty) {
      c++;
    }
  }
  return c;
};
const selectNumberOfStartedBoards = (state, couponId) => {
  var _selectCoupon18;
  let c = 0;
  const couponBoards = ((_selectCoupon18 = selectCoupon(state, couponId)) === null || _selectCoupon18 === void 0 ? void 0 : _selectCoupon18.boards) || [];
  for (const board of couponBoards) {
    let boardIsEmpty = true;
    for (const row of board.signs) {
      if (row.includes(true)) {
        boardIsEmpty = false;
        break;
      }
    }
    if (!boardIsEmpty) {
      c++;
    }
  }
  return c;
};
const selectTotalAmountOfRows = (state, couponId) => {
  var _selectCoupon19;
  return ((_selectCoupon19 = selectCoupon(state, couponId)) === null || _selectCoupon19 === void 0 ? void 0 : _selectCoupon19.totalAmountOfRows) || 0;
};
const selectUSignsCount = function (state, couponId) {
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let c = 0;
  const uSigns = selectUSigns(state, couponId, board) || [];
  for (const row of uSigns) {
    if (row !== null) {
      c++;
    }
  }
  return c;
};
const selectPixSize = function (state, couponId) {
  var _selectCouponBoard7;
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails) {
    if (competitionDetails.systemType === SYSTEM_MSYS) {
      return competitionDetails.systemNumber;
    }
    return null; 
  }
  return (_selectCouponBoard7 = selectCouponBoard(state, couponId, board)) === null || _selectCouponBoard7 === void 0 ? void 0 : _selectCouponBoard7.pixSize;
};

const selectLastSelectedSystem = (state, couponId) => {
  var _selectCoupon20;
  return ((_selectCoupon20 = selectCoupon(state, couponId)) === null || _selectCoupon20 === void 0 ? void 0 : _selectCoupon20.lastSelectedSystemType) || 1;
};
const selectXpertAnalysisAuthorId = (state, couponId) => {
  var _selectCoupon21;
  return (_selectCoupon21 = selectCoupon(state, couponId)) === null || _selectCoupon21 === void 0 ? void 0 : _selectCoupon21.xpertAnalysisAuthorId;
};
const makeSelectIsCouponEmpty = () => RTK.createSelector(selectSigns, selectUserSigns, (signs, userSigns) => !flatArray(signs || []).some(sign => sign) && !flatArray(userSigns || []).some(sign => sign));
const selectIsRSystemChosen = (state, couponId) => selectSelectedSystemType(state, couponId) === SYSTEM_RSYS && selectRSystem(state, couponId) !== undefined;
const selectIsUSystemChosen = (state, couponId) => selectSelectedSystemType(state, couponId) === SYSTEM_USYS && selectUSystem(state, couponId) !== undefined;
const selectIsReduceSystemChosen = (state, couponId) => selectSelectedSystemType(state, couponId) === SYSTEM_REDUCERA_FRITT;
const selectIsExternalMsys = (state, couponId) => {
  var _selectCoupon22;
  return ((_selectCoupon22 = selectCoupon(state, couponId)) === null || _selectCoupon22 === void 0 || (_selectCoupon22 = _selectCoupon22.boards[0]) === null || _selectCoupon22 === void 0 ? void 0 : _selectCoupon22.type) === constants.boardType.BOARD_MULTIPLE;
};
const selectPixSizeSingleRow = (state, couponId) => {
  var _selectCoupon23;
  return (((_selectCoupon23 = selectCoupon(state, couponId)) === null || _selectCoupon23 === void 0 ? void 0 : _selectCoupon23.boards) || []).filter(board => board.pixSize).length;
};
const makeSelectOutcomesWithReductionsFromBitmap = () => createSelector(selectSigns, selectReductionSelections, (selectedSigns, selectedReductions) => selectedSigns.map((event, index) => ({
  outcomes: event.map(participantOutcomes => getBitmapIndices(participantOutcomes).flat()),
  reductions: selectedReductions[index]
})));
const getNumberOfEventsCompletedForScore = (state, couponId) => {
  const coupon = selectCoupon(state, couponId);
  let completedEventSelections = 0;
  const engineType = selectCouponSelectorType(state);
  if (engineType === engineCouponSelectorType.MATRIX) {
    return completedEventSelections;
  }
  coupon === null || coupon === void 0 || coupon.boards.forEach((board, index) => {
    const boardIndex = index;
    board.signs.forEach((signArr, index) => {
      const eventNumber = index + 1;
      if (selectSROWForEvent(state, couponId, eventNumber, boardIndex).length > 0) {
        completedEventSelections++;
      }
    });
  });
  return completedEventSelections;
};
const selectValidCouponEvents = createSelector((state, couponId) => selectCoupon(state, couponId), function (state, couponId) {
  let board = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return selectCouponBoard(state, couponId, board);
}, (state, couponId) => getNumberOfEventsCompletedForScore(state, couponId), (coupon, board, numberOfEventsCompletedForScore) => {
  const signs = board === null || board === void 0 ? void 0 : board.signs;
  let numberOfEventsStarted = 0;
  if ((coupon === null || coupon === void 0 ? void 0 : coupon.engineType) === constants.engineCouponSelectorType.BINARY) {
    const productId = coupon === null || coupon === void 0 ? void 0 : coupon.productId;
    const engineConfig = selectEngineDefinitionByProductId(productId);
    const isEventTypeResult = engineConfig.outcomes.eventTypeId === EventTypeId.RESULT;
    if (isEventTypeResult) {
      numberOfEventsStarted = numberOfEventsCompletedForScore;
    } else {
      numberOfEventsStarted = getNumberOfEventsStartedForBinary(signs);
    }
  } else {
    numberOfEventsStarted = getNumberOfEventsStartedForMatrix(signs);
  }
  return numberOfEventsStarted;
});
setGlobal('svs.components.tipsen.engine.selectors.couponSelectors', {
  makeSelectEventOutcomesFromBitmap,
  makeSelectIsCouponEmpty,
  makeSelectOutcomesWithReductionsFromBitmap,
  selectAllSigns,
  selectBetslipCouponType,
  selectBoardQuickpickVariant,
  selectBoardUserSigns,
  selectCoupon,
  selectCouponProductId,
  selectCouponBoardEmpty,
  selectCouponEmpty,
  selectCouponEventCount,
  selectValidCouponEvents,
  selectCouponQuickpickVariant,
  selectCouponSelectorType,
  selectCouponSet,
  selectCouponSignCount,
  selectCouponStatus,
  selectCurrentAddonPixSizeIndex,
  selectCurrentBetAmount,
  selectCurrentSportkryssAmount,
  selectCurrentSportkryssFactor,
  selectCurrentWeek,
  selectEventSelections,
  selectEventUserSelections,
  selectEventUSign,
  selectFullHedges,
  selectHalfHedges,
  selectHasBoardSignsNotByUser,
  selectHasBoardUserSigns,
  selectHasUserSigns,
  selectIsCouponExternal,
  selectIsCouponModified,
  selectIsCouponSingleRows,
  selectIsEventReduced,
  selectIsExternalMsys,
  selectIsReduceSystemChosen,
  selectIsRelatedCouponValid,
  selectIsRSystemChosen,
  selectIsSportkryssSupported,
  selectIsTransferable,
  selectIsUSystemChosen,
  selectLastSelectedSystem,
  selectMSign,
  selectMSigns,
  selectMSignsCount,
  selectNumberOfBoards,
  selectNumberOfCompletedBoards,
  selectNumberOfStartedBoards,
  selectPixSize,
  selectPixSizeSingleRow,
  selectPrice,
  selectQuickpickValue: selectBoardQuickpickVariant,
  selectReduceCount,
  selectReductionForEvent,
  selectReductions,
  selectReductionSelections,
  selectReductionSelectionsForEvent,
  selectRelatedDrawCoupon,
  selectRequiredHedges,
  selectRequiredUSigns,
  selectRSystem,
  selectSelectedSystemType,
  selectSignDistribution,
  selectSigns,
  selectSportkryssNumber,
  selectSportkryssPrice,
  makeSelectSROWCombinationCount,
  selectSROWForEvent,
  selectSystemSize,
  selectSystemType,
  selectSystemTypeForEvent,
  selectTotalAmountOfRows,
  selectUserSigns,
  selectUserSystemSize,
  selectUSigns,
  selectUSignsCount,
  selectUSystem,
  selectXpertAnalysisAuthorId,
  selectIsEveryMatchSelectedWithUserSigns,
  selectIsEveryMatchSelected,
  makeSelectAllSROWCombinations,
  selectExternalSystemChunkSize
});

 })(window);